
import { defineComponent, ref } from "vue";
import RmMark from "./rmMark"

export default defineComponent({
  setup() {
    const rmMark = new RmMark();
    const url = ref("");
    const videoInfo = rmMark.videoInfo;
    const loading = rmMark.fullscreenLoading;
    const deWatermark = (url: string) => rmMark.deWatermark(url);
    const download = () => rmMark.download();
    return {
      url,
      videoInfo,
      loading,
      deWatermark,
      download
    }
  }
})
