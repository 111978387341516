<template>
  <div id="rm-watermark" class="center">
    <div class="tiktok">
      <div class="title">
        <strong>去除抖音、快手视频水印</strong>
        <p class="tip">友情提示：输入短视频链接点击去水印按钮，预览视频可能无法显示，点击下载视频按钮即可浏览下载！(下载视频需要将文件后缀名改为.mp4)</p>
      </div>
      <div class="card">
        <a-input placeholder="请输入抖音、快手短视频链接" v-model:value="url" />
        <div class="remove">
          <a-button :loading="loading" @click="deWatermark(url)" type="primary">去水印</a-button>
        </div>
        <div v-if="videoInfo">
          <div v-if="videoInfo.title" class="title">{{videoInfo.title}}</div>
          <div class="video">                                      
            <div id="player">
							<video ref="video" controls
								style="margin-top: 10px;" 
								width="100%" 
								height="400" 
								:poster="videoInfo.cover" 
								:src="videoInfo.video_url">
							</video>
            </div>
          </div>
          <div class="download">
            <a-button @click="download" type="primary">下载视频</a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import RmMark from "./rmMark"

export default defineComponent({
  setup() {
    const rmMark = new RmMark();
    const url = ref("");
    const videoInfo = rmMark.videoInfo;
    const loading = rmMark.fullscreenLoading;
    const deWatermark = (url: string) => rmMark.deWatermark(url);
    const download = () => rmMark.download();
    return {
      url,
      videoInfo,
      loading,
      deWatermark,
      download
    }
  }
})
</script>

<style lang="scss">
  .tiktok {
    min-height: 400px;

    > .title {
        font-size: 25px;
        color: #037afb;
        text-align: center;
        margin-top: 50px;

        .tip {
          font-size: 15px;
        }
      }

    .card {
      width: 70%;
      margin: 50px auto;
      padding: 20px;
      box-shadow: 0 3px 10px rgba(62,85,120,.045);
      background: white;

      .remove {
        margin-top: 15px;

        button {
          width: 100%;
        }
      }

      .video {
        display: flex;
        justify-content: center;
        margin-top: 15px;
      }

      .title {
        background: #f2f2f2;
        padding: 10px;
        margin: 15px 0;
        color: #037afb;
      }

      .download {
        display: flex;
        justify-content: center;

        button {
          width: 100%;
          margin: 15px 0;
        }
      }
    }
  }

  #video {
    width: 100%;
    height: 400px;
  }
  
  @media screen and (max-width: 800px) {
    .tiktok .card {
      width: 95%;
    }
    .tiktok > .title {
      font-size: 26px;
    }
  }
</style>