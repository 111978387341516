import { message } from "ant-design-vue";
import { rmDouYin, rmKuaiShou } from "@/api"
import { ref } from "vue";

export default class RmMark {
  videoInfo = ref("");
  fullscreenLoading = ref(false);

  deWatermark(url: string) {
    if (url == "") {
      message.warning("请输入快手或抖音短视频链接");
    } else {
      let sign = "0"
      try {
        sign = url.split(".")[1];
      } catch (e) {

      }
      switch (sign) {
        case "kuaishouapp": 
          this.kuaishou(url);
          break;
        case "douyin": 
          this.douyin(url);
          break;
        default:
          message.warning("请输入正确的快手或抖音短视频链接");
          break;
      }
    }
  }
  // 抖音
  douyin(url: string) {
    this.fullscreenLoading.value = true;
    rmDouYin({url}).then((res: any) => {
      if (res.code == 200) {
        this.videoInfo.value = res.data.data;
        this.fullscreenLoading.value = false;
      } else {
        this.fullscreenLoading.value = false;
        message.warning("请输入正确的抖音短视频链接！");
      }
    })
    .catch(() => {
      this.fullscreenLoading.value = false;
      message.error("服务器错误！");
    })    
  }
  // 快手
  kuaishou(url: string) {
    this.fullscreenLoading.value = true;
    rmKuaiShou({url}).then((res: any) => {
      if (res.code == 200) {
        this.videoInfo.value = res.data.data;
        this.fullscreenLoading.value = false;
      } else {
        this.fullscreenLoading.value = false;
        message.warning("请输入正确的快手短视频链接！");
      }
    })
    .catch(() => {
      this.fullscreenLoading.value = false;
      message.error("服务器错误！");
    })    
  }
  // 下载
  download() {
    window.open((this.videoInfo as any).value.video_url);
  }
}